import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/partials/seo'
import Layout from '../components/partials/layout'
import Header from '../components/home/header'
import Skeeled from '../components/skeeled'

class JobsIndex extends React.Component {
  render() {
    const cookies = get(this, 'props.data.contentfulCookies')
    const menuItems = get(this, 'props.data.allContentfulMenuItem.nodes')
    const headerImage = get(this, 'props.data.contentfulAsset')

    return (
      <Layout
        location={this.props.location}
        menuItems={menuItems}
        cookies={cookies}
      >
        <Header gatsbyImage={headerImage} />
        <Seo />
        <Skeeled />
      </Layout>
    )
  }
}

export default JobsIndex

export const pageQuery = graphql`
  query JobsQuery {
    contentfulCookies {
      linkName
      linkUrl
      title
      content
    }
    allContentfulMenuItem(sort: { fields: [order] }) {
      nodes {
        node_locale
        code
        name
        url
      }
    }
    contentfulAsset(title: { eq: "jobs" }) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
        width: 6720
        height: 4480
      )
    }
  }
`
